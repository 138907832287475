.order-widget-wrapper {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;
  color: #121212;
  left: 0;
}

.order-widget-wrapper .price fieldset {
  border-color: #121212;
}

.lg-screen .order-widget-wrapper {
  width: calc(100% - 310px);
  left: 305px;
}

.order-widget {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 600px;
  margin: auto auto 0 auto;
}

.order-widget .item-options > div {
  cursor: pointer;
}

.order-widget .row.bottom {
  padding: 1rem 0.5rem;
  margin: 1rem 0.5rem;
  background: rgba(0, 0, 0, 0.02);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.order-widget-wrapper.closed {
  max-height: 0;
  transition: 0.5s max-height ease-in-out;
}

.order-widget-wrapper.open {
  height: 100%;
  max-height: 100%;
  transition: 0.5s max-height ease-in-out;
  z-index: 1101;
  display: flex;
}

.order-widget-wrapper .bg {
  position: fixed;
  height: 0;
  z-index: -1;
  opacity: 0;
  transition: 0.5s opacity ease-in-out;
}

.order-widget-wrapper.open .bg {
  inset: 0;
  height: unset;
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 1);
}

.current-order-items {
  padding: 0.5rem 1rem 2rem 1rem;
  display: flex;
  align-items: center;
  background-color: white;
  height: 6.5rem;
}

.bubbles {
  display: flex;
  overflow: auto;
  height: 4rem;
  margin-right: 0.5rem;
  align-items: center;
}

.order-bubble {
  padding-right: 1rem;
}

.order-bubble > div {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.1);
}

.order-bubble > div > span {
  display: flex;
  height: 1.25rem;
}

.order-widget .selected-item {
  background-color: white;
  /* padding: 1rem 1rem 0.5rem 1rem; */
  overflow: auto;
  border-radius: 24px 24px 0 0;
}

.order-widget .selected-item .item {
  margin: 0;
  border: 0;
}

.order-widget .selected-item .item .MuiInputBase-root > div {
  padding: 0 32px 0 0;
  padding-bottom: 0;
}

.order-widget .selected-item .item .MuiInputBase-root fieldset {
  border-color: transparent;
}

.order-widget .quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.order-widget .quantity > div {
  width: 33%;
}

.order-widget .quantity .mid {
  display: flex;
  justify-content: center;
}

.order-widget .quantity .mid > div {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.order-widget .quantity .add,
.order-widget .quantity .remove {
  background-color: #121212;
  color: white;
  border-radius: 50%;
  display: flex;
  padding: 0.5rem;
  cursor: pointer;
}

.order-widget .quantity .qy {
  font-size: 125%;
}

.selected-qty {
  position: absolute;
  width: 1.75rem;
  height: 1.75rem;
  background: #121212;
  color: white;
  font-weight: bold;
  bottom: 0;
  right: 0;
  transform: translate(25%, 25%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid white;
}

.order-widget .add-basket {
  display: flex;
  color: black;
  cursor: pointer;
}

.order-widget .add-basket.deactivated {
  color: lightgray;
  cursor: default;
}

.order-widget .add-basket.remove > span {
  display: flex;
  color: white;
  background-color: #fe0000;
  font-weight: bold;
}

.order-widget .add-basket.update > span {
  display: flex;
  color: white;
  background-color: #fe7f00;
  font-weight: bold;
}

.order-widget .add-basket > span {
  margin: 0.5rem auto 0.25rem auto;
  padding: 0.25rem 0.5rem;
  border-radius: 2rem;
  border: 2px solid;
}

.order-widget .top.row {
  display: flex;
  padding: 1rem;
  justify-content: space-between;
}
