*,
::after,
::before {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-size: var(--app-font-size, 14px);
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  padding-right: 0 !important; /* Bootstrap modal keeps adding it */
  overscroll-behavior: contain;
  overflow: unset !important;
  display: flex;
  flex-direction: row-reverse; /* Because on laptop we show the drawer and he is put at the end of body */
}

#root {
  height: 100%;
  width: 100%;
  /*overflow: scroll;*/
  background-color: white;
  visibility: hidden;
  overflow: auto;
}

.clickable {
  cursor: pointer;
}

/* TODO move it somewhere else */
.title {
  display: flex;
  width: 100%;
  overflow: auto;
  position: relative;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.title::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.title {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.lg-selector {
  display: flex;
  align-items: center;
  background-color: var(--app-bar-bg);
  color: var(--app-bar-color);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-top: 1rem;
  cursor: pointer;
  min-width: 8rem;
}

.under-construction {
  background-color: #ffffff;
  color: rgb(20, 20, 20);
  text-align: center;
  margin: auto;
  width: 60%;
  max-width: 20rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.under-construction-logo {
  margin: 1rem auto;
  width: 30%;
  max-width: 15rem;
  fill: currentColor;
}

.under-construction .available-languages {
  display: flex;
  flex-direction: column;
}

.under-construction .available-language {
  margin: 0.2rem auto 0 auto;
  cursor: pointer;
  display: flex;
}

.carrousel-content {
  display: inline-flex;
  margin: auto;
  padding: 0;
  overflow: auto;
  align-items: center;
  width: 100%;
  border-radius: 4px;
}

.lazy-image-container {
  align-items: center;
  display: flex;
  overflow-y: clip;
  height: 10rem;
  width: 100%;
  justify-content: center;
}

.lazy-image-container.small {
  height: unset;
  max-height: 14rem;
}

.lazy-image {
  display: inline-flex;
  border-radius: 6px;
  border-radius: var(--tas-rad, 6px);
  width: 100%;
  cursor: pointer;
}

.lazy-image.landscape {
  min-height: 10rem;
  max-width: 800px;
}

.lazy-image.portrait {
  height: 10rem;
}

.lazy-image.small {
  margin-top: auto;
}

.lazy-image-placeholder {
  max-width: 100%;
  max-height: 16rem;
  min-width: 20rem;
  display: flex;
  min-height: 10rem;
}

.lazy-image-placeholder div {
  margin: auto;
}

.fullscreen {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  z-index: 10000;
}

.fullscreen img {
  max-height: 100vh;
  object-fit: contain;
}

section {
  max-width: 1100px; /* use px because of rule to know if we are on laptop or not */
  margin: auto;
  background-color: transparent;
  color: var(--app-section-color, #121212);
}

.sections {
  list-style: none;
  padding: 0 0.5rem;
  display: flow-root;
}

/* TODO(gregoire) move it somewhere else */
.subsections {
  list-style: none;
  margin: 0;
  padding: 0;
}

.section-description {
  margin-bottom: 1rem;
}

.bottom-elements {
  margin-top: auto;
  padding: 1rem;
}

.bottom-elements .modal-button {
  min-width: calc(100% - 1.5rem);
}

.languages {
  list-style: none;
  padding: 0;
}

.languages h3 {
  margin-bottom: 0.5rem;
}

.languages ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.languages li {
  list-style: none;
  margin: 0.3rem 0.5rem;
  padding: 0;
}
.languages .language-selected {
  font-weight: bold;
}

.items {
  list-style: none;
  margin: 0;
  padding: 0;
  /*font-size: large;*/
}

.item {
  list-style: none;
  position: relative;
  border-radius: var(
    --tas-rad,
    6px
  ) !important; /* TODO: remove from Theme.css*/
  overflow: hidden;
  color: var(--item-color, #121212);
  background-color: var(--item-bg, #fff);
}

.item .label {
  /* display: inline-block; */
  font-weight: 400;
  /* margin-right: 0.5rem; */
  /* filter: opacity(0.65); */
  white-space: nowrap;
}

.item .icons {
  margin-top: 0.5rem;
}

.item .icon {
  display: inline-flex;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
}

.filter .icon,
.item .icon {
  width: auto;
  height: 1.5rem;
}

.footnote,
.section-description {
  display: flex;
  padding: 0 0.3rem;
  font-weight: var(--section-desc-weight, bold);
}

.footnote {
  margin-bottom: 1.4rem;
}

.footnote > div:first-child {
  margin-right: 0.3rem;
}

.item table {
  width: -webkit-fill-available;
  width: -moz-available;
}

.rainbow-container {
  position: absolute;
  inset: 0;
  overflow: hidden;
  border-radius: 6px;
}

.rainbow {
  position: relative;
  z-index: 0;
  width: 100%;
  animation: rotateC 4s linear infinite;
  height: 100%;
}

.rainbow::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: 0;
  top: 50%;
  width: 100%;
  /* min-height: 200%; */
  aspect-ratio: 1 / 1;
  background-color: #399953;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(#00c9ff, #fffde4),
    linear-gradient(#00c9ff, #fffde4), linear-gradient(#fffde4, #00c9ff),
    linear-gradient(#fffde4, #00c9ff);

  background-image: linear-gradient(#ffd700, #ffd700),
    linear-gradient(#00e2bf, #00e2bf), linear-gradient(#ff66ff, #ff66ff),
    linear-gradient(#00cbff, #00cbff);
  transform: translate(0%, -50%);
}

.rainbow-container::after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 12px;
  top: 12px;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  background-color: var(--item-bg, #fff);
  border-radius: 4px;
  box-shadow: 0px 0px 6px 8px var(--item-bg, #fff);
}

@keyframes rotateC {
  100% {
    transform: rotate(1turn);
  }
}

header > .filters-appbar {
  display: inline;
  background-color: white;
  color: black;
  text-align: center;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  transition: max-height 0.5s ease-in;
}

header > .filters-appbar.close {
  max-height: 0px;
  transition: max-height 0.5s ease-out;
}

header > .filters-appbar input {
  width: 100%;
  max-width: 800%;
  width: calc(100% - 1rem);
  max-width: calc(800px - 1rem);
  height: 1.5rem;
  margin: 0.5rem;
  border: 0px;
  border-bottom: 1px solid lightgray;
}

header {
  box-shadow: unset !important;
}

.filters {
  padding: 0 0.5rem;
}

.filters .filter {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-flex;
  vertical-align: middle;
  padding: 0.25rem;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.anchor {
  display: block;
  position: absolute;
  top: calc(-6.5rem - 56px);
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.sanchor {
  display: block;
  position: absolute;
  top: -56px;
  top: calc(-56px - 1rem);
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.contact-main,
.watermark {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-top: auto;
}

.watermark {
  font-size: 90%;
  z-index: 1;
  background: inherit;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid lightgrey;
  display: flex;
}

.contact-main > .modal-container > .modal-button,
.contact-form.of-menu button {
  width: fit-content;
  margin: 0.5rem auto;
  padding: 0.75rem;
  background-color: white;
  border: 1px solid rgba(200, 200, 200, 1);
  border-radius: 0.3rem;
}

.contact-main > .modal-container > .modal-button {
  margin-bottom: 3rem;
}

.underlined-button {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.MuiToolbar-root {
  cursor: pointer;
}

.contact-drawer {
  margin-bottom: 3rem;
}

.modal-view {
  display: flex;
}

.modal-view::before {
  content: "";
  position: absolute;
  inset: 0;
  backdrop-filter: blur(5px);
  z-index: -1;
}

.modal-view > .contact-form {
  min-height: 70%;
  width: 80%;
  background-color: white;
  margin: auto;
  border-radius: 4px;
  display: grid;
}

.contact-form > form {
  width: 90%;
  margin: auto;
  display: grid;
  min-height: 80%;
}

.contact-form > form > button {
  margin: auto;
  width: 100%;
  padding: 0.7rem;
  background-color: rgb(231, 231, 231);
  border: none;
}

.contact-form.of-menu {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  min-height: unset;
}

.contact-form.of-menu textarea,
.contact-form.of-menu input {
  border: 1px solid lightgrey;
  padding: 0.5rem;
  margin-top: 1rem;
  border-radius: 4px;
  font-family: inherit;
}

.contact-form.of-menu textarea {
  margin-top: 0.5rem;
}

.contact-form.of-menu input:focus {
  outline: none;
}

.contact-form.of-menu button {
  cursor: pointer;
  margin-top: auto;
  width: 100%;
  background-color: var(--app-bar-bg, #fff);
  color: var(--app-bar-color, #000);
  border-radius: 4px;
  font-family: inherit;
  font-weight: 600;
  margin-top: 1rem;
}

.stars {
  display: flex;
  margin: auto;
}

.stars svg {
  fill: #eec965;
  width: 1.2em;
  height: 1.2em;
}

.contact-form.of-menu .status {
  margin: auto;
}

.contact-form.of-menu .status.failed {
  color: red;
}

.contact-form.of-menu .status.success {
  color: green;
}

input[name="message"] {
  background: red;
}

.notes {
  margin-top: 0.5rem;
  font-style: italic;
}

#main-drawer {
  background-color: unset !important;
}

#main-drawer > .MuiPaper-root {
  max-width: 70%;
  min-width: 15rem;
  height: 100%;
  display: flex;
}

.menu-page header {
  z-index: 10000;
}

.menu-page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.section-collapser {
  position: absolute;
  right: -2rem;
  top: 2.2rem;
  align-items: center;
  display: none;
  display: var(--display-collapser);
}

.subsections .section-collapser {
  display: none;
  display: var(--display-collapser);
  position: absolute;
  right: -2rem;
  top: 1.25rem;
  align-items: center;
}

.menu-page .sections h2,
.menu-page .sections h3 {
  text-decoration: var(--h-decoration, underline wavy);
  text-underline-offset: 4px;
}

.order-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  /* margin-top: 0.5rem; */
  background: #93c1d5;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  padding: 0.25rem;
}

.order-button > svg {
  fill: #904740;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#root::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#root {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.options {
  list-style: none;
  margin: 0;
  padding-left: 0.4rem;
  padding-right: 0.5rem;
}

.options > li:not(:first-child) {
  margin-top: 0.5rem;
}

.selected-option {
  font-weight: bold;
}

.option-description {
  text-align: right;
  padding-right: 2rem;
}
.option-price {
  text-align: right;
}

/* Default theme definitions. These can be overriden by a restaurant-specific CSS. */
.MuiAppBar-root.MuiAppBar-colorPrimary {
  background-color: var(--app-bar-bg, #fff);
  color: var(--app-bar-color, #fff);
}

.menu-page {
  background-color: var(--app-bg, #fff);
  color: black;
}

.menu-logo {
  max-width: 18rem;
  width: 50%;
  margin: 2rem auto;
  display: var(--menu-logo, inherit);
}

.sidebar-logo {
  display: var(--sidebar-logo, inherit);
}

.MuiDrawer-paper {
  color: #636362 !important;
}

a {
  color: #121212;
  text-decoration: none;
  text-transform: none;
}

.drawer-sections {
  text-align: center;
  padding: 0 1rem;
  margin-top: 0;
  overflow: auto;
}

h3 {
  font-weight: normal;
}

.drawer-sections h3 {
  margin: 2rem 0 0 0;
}

h4 {
  font-weight: normal;
  margin: 0.75rem;
}

h2 {
  margin: 2rem 0 1.5rem 0;
}

.is-open .section-collapser {
  transform: rotate(0deg);
  transition: transform 200ms;
}

.is-closed .section-collapser {
  transform: rotate(180deg);
  transition: transform 200ms;
}

[name="custom-svg"] * {
  fill: currentColor !important;
}

.Collapsible__contentInner {
  border: 0;
  margin-bottom: 1px; /* Otherwise, does not unfold the collapsible when set to lazy*/
}

html[dir="rtl"] .label {
  margin-right: 0rem;
  margin-left: 0.4rem;
}

/** On laptop we show drawer */
#main-drawer.lg-screen {
  width: 0;
}

#main-drawer.lg-screen .MuiBackdrop-root {
  display: none !important;
}

.lg-screen .MuiAppBar-root {
  padding-left: 300px;
}

.lg-screen section {
  width: calc(100% - 300px);
  margin: auto;
  padding-left: 300px;
}

.lg-screen.contact-main .modal-container,
.lg-screen.watermark {
  margin-left: 300px;
}

.lg-screen .go-to-order {
  left: calc(50% + 150px);
}

.go-to-order {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  background: #e61b49;
  color: white;
  transform: translate(-50%, 0);
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: rgb(230 27 73) 0px 0px 8px -1px;
  font-weight: bold;
}

.watermark { /* TODO: remove */
  background: white !important;
  width: 100%;
  display: flex;
}

.watermark > a {
  color: black;
  padding: 0.5rem 0;
  margin: auto;
}

.lg-screen.watermark {
  width: calc(100% - 300px);
}

.lg-screen .MuiDrawer-paper {
  width: 300px !important;
  position: inherit;
  max-width: 100% !important;
}

.lg-screen.MuiDrawer-root {
  width: 300px !important;
}

.name {
  /* Useful */
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.filter.selected {
  font-weight: 600;
  box-shadow: 0px 0px 2px 1px #121212;
}

/* Template 3 */

.template-3 .item {
  flex-direction: row-reverse;
  display: flex;
}

.template-3 .item > div {
  margin-top: 0 !important;
}

.template-3 .lazy-image-container {
  width: 8rem;
  height: 8rem;
  margin-right: 0.5rem;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}

.template-3 .lazy-image-container::before {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.1);
  content: ""; /* TODO: show svg of camera or something*/
}

.template-3 .lazy-image-container:nth-child(n + 2) {
  display: none;
}

.template-3 .lazy-image-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.price > div {
  display: inline-flex;
  padding: 0rem 0.5rem;
  background-color: #c30010;
  color: white;
  border-radius: 4px;
  font-weight: bold;
}

/* .item .price > div {
  margin-top: 0.5rem;
} */

.item-name {
  font-size: 125%;
}

/* ----------- Non-Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
  .carrousel-content::-webkit-scrollbar {
    height: 1.2rem;
  }

  .carrousel-content::-webkit-scrollbar-track {
    background-color: rgba(210, 210, 210, 0.2);
    border-radius: 10px;
  }

  .carrousel-content::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .carrousel-content::-webkit-scrollbar {
    height: 1.2rem;
  }

  .carrousel-content::-webkit-scrollbar-track {
    background-color: rgba(210, 210, 210, 0.2);
    border-radius: 10px;
  }

  .carrousel-content::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
}
