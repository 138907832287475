html {
  scroll-behavior: smooth;
}

html, body, #root, .App {
  margin: 0;
  font-family: 'Montserrat', arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 225% !important;
  display: inline;
  position: relative;
  font-weight: 600;
}

h2 {
  font-size: 175% !important;
  font-weight: 600;
}

h1,
h2 {
  text-align: center;
}

#root {
  background-color: white;
}

:root {
  --tas-rad: 6px;
}