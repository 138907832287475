.receipt {
  padding: 1rem 0.5rem !important;
  margin: 2rem auto;
  color: #212121;
}

.receipt .order-item p:first-of-type {
  margin: 0;
}

.receipt .order-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.receipt .order-item .labels {
  font-size: 80%;
}

select.payment-options,
.receipt input[type="time"] {
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: white;
  font-family: "Montserrat";
  color: #212121;
}

select:invalid {
  color: rgba(0, 0, 0, 0.4);
  border-color: rgba(0, 0, 0, 0.23);
}

select option {
  color: #212121;
}

.dining-mode div {
  width: 100%;
}

.receipt .payment-option {
  display: flex;
  vertical-align: top;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0 0 0;
  border: 1px solid rgb(220, 220, 220);
  border-radius: var(--tas-rad, 6px);
}

.receipt .payment-option > div,
.receipt .payment-option > a {
  display: flex;
  align-items: center;
}

.receipt .payment-option > a > div:first-child {
  padding: 0.5rem;
}

.receipt .payment-option.selected,
.receipt .payment-option.selected {
  box-shadow: 1px 1px 0px 1px black;
  border-color: black;
}

.receipt .payment-option.sub {
  display: flex;
}

.receipt .payment-option.sub a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.receipt .payment-logo > div {
  width: 100%;
  height: 2rem;
}

.receipt .payment-logo {
  width: 4rem;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.receipt .modal-button.submit-order {
  /* TODO: check impact on GEO */
  cursor: pointer;
  /* padding: 0.25rem 0.5rem; */
  border-radius: 6px;
  font-size: 115%;
  min-width: 10rem;
}

.geo {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.geo > div {
  padding: 0.75rem 1rem;
  border-radius: 6px;
  width: 100%;
  background: #0b81ef;
  color: white;
  font-weight: 600;
  text-align: center;
}

.geo.success > div {
  background-color: white;
  color: inherit;
  border: 2px solid #0b81ef;
  text-align: left;
  font-weight: normal;
}

.receipt .note {
  text-align: center;
  font-size: 80%;
}

.receipt {
  background: linear-gradient(135deg, transparent 5.68px, white 5.69px) top left,
    linear-gradient(45deg, white 2.8px, transparent 2.81px) top left,
    linear-gradient(135deg, white 2.8px, transparent 2.81px) bottom left,
    linear-gradient(45deg, transparent 5.68px, white 5.69px) bottom left;
  background-repeat: repeat-x;
  background-size: 8px 4px !important;
  padding: 4px 0 !important;
  filter: drop-shadow(0 3px 3px rgba(200, 200, 200, 1));
  width: 400px;
  max-width: 90%;
}

.receipt-content {
  background-color: #fff;
  padding: 1rem;
}

.receipt-content .order-item {
  position: relative;
}

.receipt-content .order-item .clear-icon {
  position: absolute;
  right: 0;
}

.order-status {
  display: inline-flex;
  padding: 0rem 0.5rem;
  border: 2px solid;
  border-radius: 4px;
  font-weight: bold;
}

.order-status.progress {
  color: #009b4d;
}

.order-status.Sent {
  color: #0099e1;
}

.receipt .MuiInputBase-root {
  border-radius: 4px;
}

.coupon-container {
  margin-top: 0.5rem;
  display: flex;
}

.coupon-container > .check {
  margin-left: auto;
  display: flex;
  border-radius: 6px;
  padding: 0.5rem;
  cursor: pointer;
  background-color: #0b81ef;
  color: white;
  font-weight: 600;
}

.coupon-container > .check.no {
  border: 1px solid #0b81ef;
}

.coupon-container > .check.yes {
  color: green;
}
