@keyframes translate-in {
  from {
    left: -50%;
  }
  to {
    left: 50%;
  }
}

.menu-page > div:not(.contact-main) {
  z-index: 999;
}

.menu-page > div {
  background-color: inherit;
}

.dish-highlight {
  width: 100%;
  width: calc(100% - 2.5rem);
  max-width: 58rem;
  min-height: 5rem;
  position: fixed;
  left: -55%;
  bottom: 2.5rem;
  padding: 0.5rem !important;
  margin: 0 !important;
  animation: translate-in 0.75s linear 2s;
  animation-fill-mode: forwards;
  transform: translate(-50%, 0);
  border: 2px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
  border-radius: 8px;
  z-index: 2;
}

.lg-screen .dish-highlight {
  width: calc(100% - 300px - 2rem);
  transform: translate(calc(-50% + 150px), 0);
}

.dish-highlight section {
  width: 100%;
  max-width: 57rem;
  padding-left: 0;
}

.dish-highlight .clear {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  background-color: white;
  color: #121212;
  border: 1px solid lightgrey;
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chef-choice {
  margin-bottom: 0.5rem;
}

.sections > div {
  position: relative;
}

.menu-page .sections .item .description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sections.template-2 {
  display: flex;
  min-height: calc(100vh - 64px - 4.5rem - 9rem);
}

.sections.template-2 > div {
  width: 0%;
  height: 0;
}

.sections.template-2 > div.selected {
  width: 100%;
  height: auto;
  position: relative;
}

.sections.template-2 > div.selected h2 {
  display: none;
}

.selector::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

.selector::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.05);
}

.selector::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(136, 136, 136, 0.6);
}

.selector::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.6);
}

.selector {
  position: sticky;
  display: flex;
  top: 56px;
  margin-bottom: 1rem;
  background-color: var(--app-bg, #fff);
  z-index: 2;
  z-index: 1101;
  height: 5.5rem;
}

.selector {
  overflow: auto;
  display: flex;
  font-weight: 600;
  padding: 0.75rem 1rem;
  font-size: 115%;
}

.selector > div {
  padding: 0.5rem 0;
  margin-right: 1rem;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  color: var(--app-section-color, #fff);
  opacity: 0.3;
}

.selector div.selected {
  opacity: 1;
}

.labels {
  margin-block: 0.25rem;
  display: flex;
  overflow: auto;
  gap: 0.5rem;
  border-bottom: 2px dashed grey;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.labels::-webkit-scrollbar {
  display: none;
}

.labels::-webkit-scrollbar-track {
  display: none;
}

.labels::-webkit-scrollbar-thumb {
  display: none;
}

.MuiInputBase-root:before {
  border: 0px !important;
}

.MuiNativeSelect-icon {
  color: white !important;
}

.customization {
  margin-left: 1rem;
}

.customization > span {
  display: inline-block;
}

.customization > span:not(:last-child) {
  margin-right: 0.5rem;
}

.customization::before {
  content: "○";
  position: absolute;
  left: 0.5rem;
}

@media (min-width: 600px) {
  .selector {
    top: 64px;
  }

  .anchor {
    top: calc(-7.5rem - 64px);
  }
}
