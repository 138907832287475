#root {
  visibility: visible !important;
}

.sections {
  max-width: 64rem;
  width: 100%;
  padding: 0;
  margin-inline: auto;
  /* margin-top: 4rem; */
  /* min-height: 80vh; */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.logo {
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 1.5rem;
  top: 1.75rem;
  transform: translate(0px, -50%);
}

.cnav-item {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

.promotion-cards {
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.promotion-cards::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.promotion-cards {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* 
nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: black;
  position: sticky;
  top: 0;
  color: white;
  font-weight: bold;
  min-height: 3.5rem;
  z-index: 1;
}

.cnav-collapse {
  margin: 0.75rem auto auto 0.5rem;
  padding: 0.2rem 0.8rem;
  background-color: inherit;
  color: inherit;
  border-radius: 0.3rem;
  display: flex;
}

.cnav-item {
  text-align: center;
  cursor: pointer;
  padding: 0.5rem;
  display: block;
}

.cnav-content {
  overflow: hidden;
  width: 100%;
  max-height: 300px;
  transition: max-height 800ms ease-out;
}

.cnav-content--collapsed {
  width: 100%;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 500ms ease-out;
  background-color: inherit;
  z-index: 1;
} */

.upper-image {
  width: 100%;
  max-height: 50vh;
  height: 70rem;
  display: flex;
  align-items: center;
  padding-top: 4rem;
}

.upper-image div.img {
  width: 100%;
  height: 100%;
}

.paragraph {
  white-space: pre-wrap;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
/* 
.cnav-item:hover,
.cnav-item.selected {
  border-bottom: 3px solid currentColor;
} */

.watermark {
  width: 100%;
  text-align: center;
}

a {
  text-decoration: none;
}

.pp-button {
  padding: 1rem 2rem;
  border-radius: 3rem;
  color: inherit;
  background: currentColor;
  cursor: pointer;
  text-align: center;
  border: 0px;
}

.pp-button span {
  color: currentColor;
  filter: invert(1);
  font-weight: 600;
  text-decoration: none;
}

.hyphen {
  width: 2rem;
  height: 0.25rem;
  background: currentColor;
  margin: 0 1rem;
  border-radius: 4px;
}

.menu-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding-top: 3rem;
  color: white;
}

.footer a {
  color: white;
}

@media (max-width: 800px) {
  /* Hide scrollbar for Chrome, Safari and Opera */
  body::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@media (min-width: 1025px) {
  .upper-image div.img {
    border-radius: 24px;
  }
}

@media (min-width: 569px) {
  nav {
    min-height: 6rem;
    justify-content: center;
    padding-right: 7%;
  }

  .logo {
    width: 4rem;
    height: 4rem;
    top: 3rem;
  }

  .cnav-collapse {
    display: none;
  }

  .cnav-content--collapsed {
    max-height: 300px;
    display: flex;
    margin-left: auto;
    margin-right: 2rem;
    width: auto;
    min-width: 20rem;
    max-width: 100%;
  }

  .cnav-item {
    border-bottom: 0px;
    justify-content: center;
    display: flex;
    margin-left: 1.5rem;
    border-bottom: 3px solid transparent;
  }
}
