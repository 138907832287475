.time-picker > div {
  width: 100%;
}

.time-picker .MuiSelect-select {
  padding-right: 0 !important;
}

.time-picker .MuiSvgIcon-root {
  display: none;
}

.time-picker .MuiInputBase-root {
  border-radius: var(--tas-rad, 6px);
}

.time-picker div {
  font-family: 'Montserrat';
}