.section {
  width: 60rem;
  max-width: 100%;
  margin: auto;
  text-align: center;
}

.section .s-title {
  margin: 2rem 0 1.5rem 0;
  display: inline-flex;
  width: auto;
  max-width: 100%;
  align-items: center;
}

.section h2 {
  margin: 1.5rem 0;
}

#about h1 {
  margin-top: 0;
  font-weight: 600;
}

#about {
  padding: 4rem 1rem 0 1rem;
}

.paragraphs {
  padding: 0 1rem;
  text-align: left;
  white-space: pre-wrap;
}

.paragraphs h2 {
  text-align: left;
  margin-top: 3rem ;
}
